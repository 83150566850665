import * as React from 'react';
import './ProductEditPanel.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import ProductModuleEditPanel from './productModuleEditPanel/ProductModuleEditPanel';
import ProductService from '../../../services/ProductService';
import { IProductModule } from '../../../interfaces/IProductModule';
import ReactQuill from "react-quill";
import { IProductLink } from '../../../interfaces/IProductLink';
import { FilePicker } from '../../../mindjuiceApp/UI/filePicker/FilePicker';
import AdminService from '../../../services/AdminService';
import { IModuleDocument } from '../../../interfaces/IModuleDocument';
import { Icon } from '../../../mindjuiceApp/UI/icon';
import { Pivot } from '../../../mindjuiceApp/pivot/Pivot';

export interface IProps {
  appStore?: AppStore;
  update: () => void;
}

export interface IState {
  productId,
  selectedLanguage
}

@inject('appStore')
@observer
export default class ProductEditPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      productId: undefined,
      selectedLanguage: "danish"
    }
  }

  private array_move(arr, old_index, new_index): any[] {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };


  render() {
    let modules = [];
    if (this.props.appStore.productToEdit && this.props.appStore.productToEdit.modules) {
      this.props.appStore.productToEdit.modules.forEach((productModule: IProductModule, index) => {
        modules.push(
          <div
            key={`productModule_${index}`}
            className="productModule"
          >
            <div className="productModuleTitle">
              {productModule.title}
            </div>
            <button
              style={{
                float: "right",
                cursor: "copy"
              }}
              onClick={() => {
                const moduleDocuments: string[] = [];
                if (productModule.documents && productModule.documents.length > 0) {
                  productModule.documents.forEach((documentId: string) => {
                    AdminService.getFile(documentId).then((document: IModuleDocument) => {
                      AdminService.addFile({
                        product: document.product,
                        team: document.team,
                        type: document.type,
                        url: document.url,
                        fileType: document.fileType,
                        mindPaperId: document.mindPaperId,
                        icon: document.icon,
                        documentTitle: document.documentTitle,
                        openInSameWindow: document.openInSameWindow,
                        openInPopup: document.openInPopup,
                        openEmbedded: document.openEmbedded
                      }).then((newDocument: IModuleDocument) => {
                        moduleDocuments.push(newDocument.objectId);
                        if (moduleDocuments.length === productModule.documents.length) {
                          this.props.appStore.copiedModule = {
                            title: productModule.title,
                            videoUrl: productModule.videoUrl,
                            workbook: productModule.workbook,
                            workbookText: productModule.workbookText,
                            coverImage: productModule.coverImage,
                            openInSameWindow: productModule.openInSameWindow,
                            flowpaper: productModule.flowpaper,
                            documents: moduleDocuments
                          };
                        }
                      });
                    });
                  });
                } else {
                  this.props.appStore.copiedModule = {
                    title: productModule.title,
                    videoUrl: productModule.videoUrl,
                    workbook: productModule.workbook,
                    workbookText: productModule.workbookText,
                    coverImage: productModule.coverImage,
                    openInSameWindow: productModule.openInSameWindow,
                    flowpaper: productModule.flowpaper,
                    documents: moduleDocuments
                  };
                }
              }}
            >
              <Icon
                title={"Copy"}
                size={18}
                color={"#ffffff"}
                style={{
                }}
                onClick={() => { }}
              />
            </button>
            <button
              style={{
                float: "right"
              }}
              onClick={() => {
                this.props.appStore.productModuleIndesToEdit = index;
              }}
            >
              <Icon
                title={"Edit"}
                size={18}
                color={"#ffffff"}
                style={{
                }}
                onClick={() => { }}
              />
            </button>
            <button
              style={{
                float: "right",
                opacity: index === this.props.appStore.productToEdit.modules.length - 1 ? 0.5 : 1
              }}
              disabled={index === this.props.appStore.productToEdit.modules.length - 1}
              onClick={() => {
                this.props.appStore.productToEdit.modules = this.array_move(this.props.appStore.productToEdit.modules, index, index + 1);
              }}
            >
              <Icon
                title={"ArrowUp"}
                size={18}
                color={"#ffffff"}
                style={{
                }}
                onClick={() => { }}
              />
            </button>
            <button
              style={{
                float: "right",
                opacity: index === 0 ? 0.5 : 1
              }}
              disabled={index === 0}
              onClick={() => {
                this.props.appStore.productToEdit.modules = this.array_move(this.props.appStore.productToEdit.modules, index, index - 1);
              }}
            >
              <Icon
                title={"ArrowDown"}
                size={18}
                color={"#ffffff"}
                style={{
                }}
                onClick={() => { }}
              />
            </button>
          </div>
        );
      });
    }
    let links = [];
    if (this.props.appStore.productToEdit && this.props.appStore.productToEdit.links) {
      this.props.appStore.productToEdit.links.forEach((link: IProductLink, index) => {
        links.push(
          <div
            key={`productModule_${index}`}
            className="linkInput"
          >
            <div className="inputFieldLabel" style={{ width: "fit-content", height: 35, clear: "both", float: "left" }}>Url eller fil</div>
            <img width="20" height="20" style={{ marginLeft: 5, marginTop: 15, float: "left" }} src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
            <FilePicker
              url={this.state.selectedLanguage === "danish" ? link.url : link.localizedUrl && link.localizedUrl[this.state.selectedLanguage] ? link.localizedUrl[this.state.selectedLanguage] : ""}
              placeholderText={"Indsæt et link eller upload en fil..."}
              inputWidth={this.props.appStore.isMobile ? "79%" : "87%"}
              onChange={(url: string) => {
                if (this.state.selectedLanguage === "danish") {
                  link.url = url;
                } else {
                  if (!link.localizedUrl) {
                    link.localizedUrl = {};
                  }
                  link.localizedUrl[this.state.selectedLanguage] = url;
                }
                if (url && url != undefined && url !== "" && url.indexOf("mindjuice.herokuapp.com/parse/files") !== -1) {
                  const splittedUrl = url.split(".");
                  let fileType = splittedUrl[splittedUrl.length - 1];
                  AdminService.addFile({
                    url: url,
                    fileType: fileType,
                    product: this.props.appStore.productToEdit.productId,
                    team: undefined,
                    type: "productImage"
                  });
                }
              }}
            />
            <div className="inputFieldLabel" style={{ width: "fit-content", height: 35, clear: "both", float: "left" }}>Knaptekst</div>
            <img width="20" height="20" style={{ marginLeft: 5, marginTop: 15, float: "left" }} src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
            <input
              type="text"
              value={this.state.selectedLanguage === "danish" ? link.buttonTitle : link.localizedButtonTitle && link.localizedButtonTitle[this.state.selectedLanguage] ? link.localizedButtonTitle[this.state.selectedLanguage] : ""}
              onChange={(event: any) => {
                if (this.state.selectedLanguage === "danish") {
                  link.buttonTitle = event.target.value;
                } else {
                  if (!link.localizedButtonTitle) {
                    link.localizedButtonTitle = {};
                  }
                  link.localizedButtonTitle[this.state.selectedLanguage] = event.target.value;
                }
              }}
              placeholder="Knaptekst"
            />
            <div className="flowpaper">
              <input
                type="checkbox"
                checked={link.openInSameWindow}
                onChange={() => {
                  link.openInSameWindow = link.openInSameWindow != undefined ? !link.openInSameWindow : true
                }}
                style={{
                  width: 20,
                }}
              />
              Åben i samme fane
            </div>
            <button
              style={{
                marginTop: 10,
                float: "right",
                clear: "both",
                backgroundColor: "red"
              }}
              onClick={() => {
                this.props.appStore.productToEdit.links.splice(index, 1);
              }}
            >
              Slet link
            </button>
            <button
              style={{
                marginTop: 10,
                float: "right",
                opacity: this.props.appStore.productToEdit.links.length - 1 === index ? 0.5 : 1
              }}
              disabled={this.props.appStore.productToEdit.links.length - 1 === index}
              onClick={() => {
                this.props.appStore.productToEdit.links = this.array_move(this.props.appStore.productToEdit.links, index, index + 1);
              }}
            >
              <Icon
                title={"ArrowDown"}
                size={18}
                color={"#ffffff"}
                style={{
                }}
                onClick={() => { }}
              />
            </button>
            <button
              style={{
                marginTop: 10,
                float: "right",
                opacity: index === 0 ? 0.5 : 1
              }}
              disabled={index === 0}
              onClick={() => {
                this.props.appStore.productToEdit.links = this.array_move(this.props.appStore.productToEdit.links, index, index - 1);
              }}
            >
              <Icon
                title={"ArrowUp"}
                size={18}
                color={"#ffffff"}
                style={{
                }}
                onClick={() => { }}
              />
            </button>
          </div>
        );
      });
    }
    if (this.props.appStore.productToEdit && this.props.appStore.productToEdit.teaser && this.state.productId != this.props.appStore.productToEdit.productId) {
      this.setState({
        productId: this.props.appStore.productToEdit.productId
      })
    }

    return (
      <div>
        <div
          className="productEditPanelWrapper"
          style={{
            visibility: this.props.appStore.productToEdit != undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.productToEdit = undefined;
          }}
        />
        <div
          className="productEditPanel"
          style={{
            right: this.props.appStore.productToEdit != undefined ? 0 : -620
          }}
        >
          {this.props.appStore.productToEdit != undefined &&
            <div style={{
              width: "100%",
              position: "relative",
              float: "left"
            }}>
              <div className="editPanelCloseBtn"
                onClick={() => this.props.appStore.productToEdit = undefined}
              />
              <h3>{this.props.appStore.productToEdit.productType === "Event" ? `Rediger event` : `Rediger produkt`}</h3>
              <Pivot
                highlightColor="#b362fa"
                textColor="#333333"
                items={[
                  {
                    key: "danish",
                    text: window.loc.strings.Danish,
                  },
                  {
                    key: "english",
                    text: window.loc.strings.English,
                  }
                ]}
                style={{
                  fontSize: "14px",
                  textTransform: "uppercase",
                  width: "100%"
                }}
                selectedKey={this.state.selectedLanguage}
                select={(selectedLanguage: string) => {
                  this.setState({ selectedLanguage });
                }}
              />
              {this.props.appStore.productToEdit.productType !== "Event" &&
                <div>
                  <div className="inputFieldLabel">Label</div>
                  <img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
                  <input
                    type="text"
                    value={this.state.selectedLanguage === "danish" ? this.props.appStore.productToEdit.type : this.props.appStore.productToEdit.localizedType && this.props.appStore.productToEdit.localizedType[this.state.selectedLanguage] ? this.props.appStore.productToEdit.localizedType[this.state.selectedLanguage] : ""}
                    onChange={(event: any) => {
                      if (this.state.selectedLanguage === "danish") {
                        this.props.appStore.productToEdit.type = event.target.value;
                      } else {
                        if (!this.props.appStore.productToEdit.localizedType) {
                          this.props.appStore.productToEdit.localizedType = {};
                        }
                        this.props.appStore.productToEdit.localizedType[this.state.selectedLanguage] = event.target.value;
                      }
                    }}
                  />
                </div>
              }
              <div className="inputFieldLabel">Titel</div>
              <img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
              <input
                type="text"
                value={this.state.selectedLanguage === "danish" ? this.props.appStore.productToEdit.title : this.props.appStore.productToEdit.localizedTitle && this.props.appStore.productToEdit.localizedTitle[this.state.selectedLanguage] ? this.props.appStore.productToEdit.localizedTitle[this.state.selectedLanguage] : ""}
                onChange={(event: any) => {
                  if (this.state.selectedLanguage === "danish") {
                    this.props.appStore.productToEdit.title = event.target.value;
                  } else {
                    if (!this.props.appStore.productToEdit.localizedTitle) {
                      this.props.appStore.productToEdit.localizedTitle = {};
                    }
                    this.props.appStore.productToEdit.localizedTitle[this.state.selectedLanguage] = event.target.value;
                  }
                }}
              />
              <div className="inputFieldLabel">{this.props.appStore.productToEdit.productType === "Event" ? `Mobilbillede` : `Billede på produktkort`}</div>
              <img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
              <FilePicker
                url={this.state.selectedLanguage === "danish" ? this.props.appStore.productToEdit.image : this.props.appStore.productToEdit.localizedImage && this.props.appStore.productToEdit.localizedImage[this.state.selectedLanguage] ? this.props.appStore.productToEdit.localizedImage[this.state.selectedLanguage] : ""}
                placeholderText={"Indsæt et link eller upload en billedfil..."}
                inputWidth={this.props.appStore.isMobile ? "81%" : "87%"}
                onChange={(url: string) => {
                  if (this.state.selectedLanguage === "danish") {
                    this.props.appStore.productToEdit.image = url;
                  } else {
                    if (!this.props.appStore.productToEdit.localizedImage) {
                      this.props.appStore.productToEdit.localizedImage = {};
                    }
                    this.props.appStore.productToEdit.localizedImage[this.state.selectedLanguage] = url;
                  }
                  if (url && url != undefined && url !== "" && url.indexOf("mindjuice.herokuapp.com/parse/files") !== -1) {
                    const splittedUrl = url.split(".");
                    let fileType = splittedUrl[splittedUrl.length - 1];
                    AdminService.addFile({
                      url: url,
                      fileType: fileType,
                      product: this.props.appStore.productToEdit.productId,
                      team: undefined,
                      type: "productImage"
                    });
                  }
                }}
              />
              <div className="inputFieldLabel">Bannerbillede</div>
              <img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
              <FilePicker
                url={this.state.selectedLanguage === "danish" ? this.props.appStore.productToEdit.banner : this.props.appStore.productToEdit.localizedBanner && this.props.appStore.productToEdit.localizedBanner[this.state.selectedLanguage] ? this.props.appStore.productToEdit.localizedBanner[this.state.selectedLanguage] : ""}
                placeholderText={"Indsæt et link eller upload en billedfil..."}
                inputWidth={this.props.appStore.isMobile ? "81%" : "87%"}
                onChange={(url: string) => {
                  if (this.state.selectedLanguage === "danish") {
                    this.props.appStore.productToEdit.banner = url;
                  } else {
                    if (!this.props.appStore.productToEdit.localizedBanner) {
                      this.props.appStore.productToEdit.localizedBanner = {};
                    }
                    this.props.appStore.productToEdit.localizedBanner[this.state.selectedLanguage] = url;
                  }
                  if (url && url != undefined && url !== "" && url.indexOf("mindjuice.herokuapp.com/parse/files") !== -1) {
                    const splittedUrl = url.split(".");
                    let fileType = splittedUrl[splittedUrl.length - 1];
                    AdminService.addFile({
                      url: url,
                      fileType: fileType,
                      product: this.props.appStore.productToEdit.productId,
                      team: undefined,
                      type: "productImage"
                    });
                  }
                }}
              />
              {this.props.appStore.productToEdit.productType !== "Event" &&
                <div>
                  <div className="inputFieldLabel">Antal deltagere</div>
                  <input
                    type="number"
                    value={this.props.appStore.productToEdit.numberOfusers}
                    onChange={(event: any) => {
                      this.props.appStore.productToEdit.numberOfusers = event.target.value;
                    }}
                  />
                </div>
              }
              {this.props.appStore.productToEdit.productType !== "Event" &&
                <div>
                  <div className="inputFieldLabel">Antal moduler</div>
                  <input
                    type="number"
                    value={this.props.appStore.productToEdit.numberOfModules}
                    onChange={(event: any) => {
                      this.props.appStore.productToEdit.numberOfModules = event.target.value;
                    }}
                  />
                </div>
              }
              {this.props.appStore.productToEdit.productType !== "Event" &&
                <div>
                  <div className="inputFieldLabel">Antal videoer</div>
                  <input
                    type="number"
                    value={this.props.appStore.productToEdit.numberOfVideos}
                    onChange={(event: any) => {
                      this.props.appStore.productToEdit.numberOfVideos = event.target.value;
                    }}
                  />
                </div>
              }
              {this.props.appStore.productToEdit.productType !== "Event" &&
                <div>
                  <div className="inputFieldLabel">Produkt id</div>
                  <input
                    type="text"
                    value={this.props.appStore.productToEdit.productId}
                    onChange={(event: any) => {
                      this.props.appStore.productToEdit.productId = event.target.value;
                    }}
                  />
                </div>
              }
              {this.props.appStore.productToEdit.productType !== "Event" &&
                <div>
                  <div className="inputFieldLabel">Adgang i dage</div>
                  <input
                    type="number"
                    value={this.props.appStore.productToEdit.accessPeriod}
                    onChange={(event: any) => {
                      this.props.appStore.productToEdit.accessPeriod = event.target.value;
                    }}
                  />
                </div>
              }
              <div className="inputFieldLabel">{this.props.appStore.productToEdit.productType === "Event" ? `Eventlink` : `Produktlink`}</div>
              <img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
              <input
                type="text"
                value={this.state.selectedLanguage === "danish" ? this.props.appStore.productToEdit.link : this.props.appStore.productToEdit.localizedLink && this.props.appStore.productToEdit.localizedLink[this.state.selectedLanguage] ? this.props.appStore.productToEdit.localizedLink[this.state.selectedLanguage] : ""}
                onChange={(event: any) => {
                  if (this.state.selectedLanguage === "danish") {
                    this.props.appStore.productToEdit.link = event.target.value;
                  } else {
                    if (!this.props.appStore.productToEdit.localizedLink) {
                      this.props.appStore.productToEdit.localizedLink = {};
                    }
                    this.props.appStore.productToEdit.localizedLink[this.state.selectedLanguage] = event.target.value;
                  }
                }}
              />
              {this.props.appStore.productToEdit.productType !== "Event" &&
                <div>
                  <div className="flowpaper">
                    <input
                      type="checkbox"
                      checked={this.props.appStore.productToEdit.openEmbedded}
                      onChange={() => {
                        this.props.appStore.productToEdit.openEmbedded = this.props.appStore.productToEdit.openEmbedded != undefined ? !this.props.appStore.productToEdit.openEmbedded : true;
                      }}
                      style={{
                        width: 20,
                      }}
                    />
                    Vis produktlink som embedded side
                  </div>
                </div>
              }
              {this.props.appStore.productToEdit.productType !== "Event" &&
                <div>
                  <div className="inputFieldLabel">Teaser</div>
                  <img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
                  <ReactQuill
                    theme="snow"
                    value={this.state.selectedLanguage === "danish" ? this.props.appStore.productToEdit.teaser ?? "" : this.props.appStore.productToEdit.localizedTeaser && this.props.appStore.productToEdit.localizedTeaser[this.state.selectedLanguage] ? this.props.appStore.productToEdit.localizedTeaser[this.state.selectedLanguage] : ""}
                    onChange={(html) => {
                      if (this.state.selectedLanguage === "danish") {
                        this.props.appStore.productToEdit.teaser = html;
                      } else {
                        if (!this.props.appStore.productToEdit.localizedTeaser) {
                          this.props.appStore.productToEdit.localizedTeaser = {};
                        }
                        this.props.appStore.productToEdit.localizedTeaser[this.state.selectedLanguage] = html;
                      }
                    }}
                    style={{
                      width: "100%",
                      float: "left",
                      marginTop: 7,
                      backgroundColor: "#ffffff",
                      borderRadius: 5
                    }}
                    modules={{
                      // #3 Add "image" to the toolbar
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" }
                        ],
                        ["link", "image"],
                        ["clean"]
                      ],
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "indent",
                      "link",
                      "image",
                      "imageBlot" // #5 Optinal if using custom formats
                    ]}
                  />
                </div>
              }
              {this.props.appStore.productToEdit.productType !== "Event" &&
                <div>
                  <div
                    className="inputFieldLabel"
                    style={{
                      marginBottom: 10,
                      float: "left",
                      marginTop: 40,
                      width: "100%"
                    }}
                  >
                    Links
                  </div>
                  {links}
                  <button
                    style={{
                      marginTop: 20,
                      marginBottom: 30
                    }}
                    onClick={() => {
                      if (this.props.appStore.productToEdit.links === undefined) {
                        this.props.appStore.productToEdit.links = [];
                      }
                      this.props.appStore.productToEdit.links.push({
                        url: "",
                        buttonTitle: ""
                      });
                    }}
                  >
                    Tilføj link
                  </button>
                </div>
              }

              {this.props.appStore.productToEdit.productType !== "Event" &&
                <div>
                  <div
                    className="inputFieldLabel"
                    style={{
                      width: "100%"
                    }}>
                    Moduler
                  </div>
                  {modules}
                  <button
                    style={{
                      marginTop: 20,
                      marginBottom: 30
                    }}
                    onClick={() => {
                      if (this.props.appStore.productToEdit.modules === undefined) {
                        this.props.appStore.productToEdit.modules = [];
                      }
                      this.props.appStore.productToEdit.modules.push({});
                      this.props.appStore.productModuleIndesToEdit = this.props.appStore.productToEdit.modules.length - 1;
                    }}
                  >
                    Tilføj modul
                  </button>
                  <button
                    style={{
                      marginTop: 20,
                      marginBottom: 30,
                      opacity: this.props.appStore.copiedModule ? 1 : 0.5
                    }}
                    disabled={!this.props.appStore.copiedModule}
                    onClick={() => {
                      if (this.props.appStore.productToEdit.modules === undefined) {
                        this.props.appStore.productToEdit.modules = [];
                      }
                      if (this.props.appStore.copiedModule) {
                        this.props.appStore.productToEdit.modules.push(this.props.appStore.copiedModule);
                      }
                    }}
                  >
                    Indsæt kopieret modul
                  </button>
                </div>
              }
              <div className="publicToggle">
                <input
                  type="checkbox"
                  checked={this.props.appStore.productToEdit.public}
                  onChange={() => {
                    this.props.appStore.productToEdit.public = !this.props.appStore.productToEdit.public
                  }}
                  style={{
                    width: 20,
                  }}
                />
                {this.props.appStore.productToEdit.productType === "Event" ? `Eventet er udgivet` : `Produktet er udgivet`}
              </div>
              <button
                style={{
                  clear: "both"
                }}
                onClick={() => {
                  if (this.props.appStore.productToEdit.productId || this.props.appStore.productToEdit.productType === "Event") {
                    if (this.props.appStore.productToEdit.objectId !== undefined) {
                      ProductService.updateProduct(this.props.appStore.productToEdit).then(() => {
                        this.props.appStore.productToEdit = undefined;
                        this.props.update();
                      });
                    } else {
                      ProductService.createProduct(this.props.appStore.productToEdit).then(() => {
                        this.props.appStore.productToEdit = undefined;
                        this.props.update();
                      });
                    }
                  }
                }}
              >
                {this.props.appStore.productToEdit.objectId !== undefined ? "Opdater" : "Opret"}
              </button>
              <button
                style={{
                  marginLeft: 10,
                  backgroundColor: "gray"
                }}
                onClick={() => {
                  this.props.appStore.productToEdit = undefined;
                }}
              >
                Annuller
              </button>
              <button
                onClick={() => {
                  ProductService.deleteProduct(this.props.appStore.productToEdit).then(() => {
                    this.props.appStore.productToEdit = undefined;
                    this.props.update();
                  });
                }}
                style={{
                  marginLeft: 10,
                  backgroundColor: "red"
                }}
              >
                Slet
              </button>
            </div>
          }
        </div>
        <ProductModuleEditPanel
          productId={this.props.appStore.productToEdit && this.props.appStore.productToEdit.objectId}
          selectedLanguage={this.state.selectedLanguage}
          onChangeLanguage={(selectedLanguage) => this.setState({ selectedLanguage })}
        />
      </div>
    );
  }
}