import { IUser } from "../interfaces/IUser";
import { IProduct } from "../interfaces/IProduct";
import { IProductIntro } from "../interfaces/IProductIntro";
import { IProductAccess } from "../interfaces/IProductAccess";
import { ITeam } from "../interfaces/ITeam";
import { INote, INotePage } from "../interfaces/INote";
import { IMindPaper } from "../interfaces/IMindPaper";

export default class ProductService {

  public static getProducts(): Promise<IProduct[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Products/?order=index`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static createProduct(product: IProduct): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Products/`, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(
          product
        )
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static updateProduct(product: IProduct): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Products/${product.objectId}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          productType: product.productType,
          type: product.type,
          title: product.title,
          productId: product.productId,
          teaser: product.teaser,
          links: product.links,
          image: product.image,
          banner: product.banner,
          link: product.link,
          modules: product.modules,
          accessPeriod: product.accessPeriod,
          public: product.public,
          index: product.index,
          openEmbedded: product.openEmbedded,
          numberOfusers: product.numberOfusers,
          numberOfModules: product.numberOfModules,
          numberOfVideos: product.numberOfVideos,
          localizedType: product.localizedType,
          localizedTitle: product.localizedTitle,
          localizedImage: product.localizedImage,
          localizedBanner: product.localizedBanner,
          localizedLink: product.localizedLink,
          localizedTeaser: product.localizedTeaser
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static deleteProduct(product: IProduct): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Products/${product.objectId}`, {
        method: "DELETE",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  ///////////////////////////// ProductIntro ////////////////////////////////

  public static getProductIntro(): Promise<IProductIntro> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/ProductIntro/`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results[0]);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static updateProductIntro(productIntro: IProductIntro): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/ProductIntro/${productIntro.objectId}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          title: productIntro.title,
          text: productIntro.text,
          titleEN: productIntro.titleEN,
          textEN: productIntro.textEN
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  ///////////////////////////// ProductAccess ////////////////////////////////

  public static userAccess(user: string): Promise<any[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/ProductAccess/?where={\"user\":\"${user}\"}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static hasUserAccessToProduct(productId: string, user: string, accessPeriod: any): Promise<boolean> {
    return new Promise((resolve) => {
      let accessObject;
      if (localStorage.getItem(`Mindjuice_hasUserAccessToProduct_${productId}`) != undefined) {
        accessObject = JSON.parse(localStorage.getItem(`Mindjuice_hasUserAccessToProduct_${productId}`));
      }
      if (accessObject && accessObject?.date && (new Date(accessObject?.date).getUTCDate() === new Date().getUTCDate() && new Date(accessObject?.date).getUTCMonth() === new Date().getUTCMonth())) {
        resolve(accessObject?.hasAccess);
      } else {
        fetch(`https://mindjuice.herokuapp.com/parse/classes/ProductAccess/?where={\"product\":\"${productId}\",\"user\":\"${user}\"}`, {
          headers: {
            'X-Parse-Application-Id': 'mindjuice'
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(false);
          } else {
            return response.json();
          }
        }).then(response => {
          if (response.results.length > 0) {
            const userAccess = response.results[0];
            const accessPeriode: any = (accessPeriod !== undefined ? accessPeriod : 365)
            var time_difference = new Date().getTime() - new Date(userAccess.updatedAt).getTime();
            const daysLeft = accessPeriode - Math.round(time_difference / (1000 * 60 * 60 * 24));
            let hasAccess;
            if (daysLeft > 0 || accessPeriod == undefined || accessPeriode === "") {
              hasAccess = true;
            } else {
              hasAccess = false;
            }
            localStorage.setItem(`Mindjuice_hasUserAccessToProduct_${productId}`, JSON.stringify({
              hasAccess: hasAccess,
              date: new Date()
            }));
            resolve(hasAccess);
          } else {
            localStorage.setItem(`Mindjuice_hasUserAccessToProduct_${productId}`, JSON.stringify({
              hasAccess: false,
              date: new Date()
            }));
            resolve(false);
          }
        }).catch((error) => {
          console.log(error);
          resolve(false);
        });
      }
    });
  }

  public static hasUserAccessToProductAndTeam(productId: string, user: string, accessPeriod: any, team: string): Promise<boolean> {
    return new Promise((resolve) => {
      let accessObject;
      if (localStorage.getItem(`Mindjuice_hasUserAccessToProductAndTeam_${productId}_${team}`) != undefined) {
        accessObject = JSON.parse(localStorage.getItem(`Mindjuice_hasUserAccessToProductAndTeam_${productId}_${team}`));
      }
      if (accessObject && accessObject?.date && (new Date(accessObject?.date).getUTCDate() === new Date().getUTCDate() && new Date(accessObject?.date).getUTCMonth() === new Date().getUTCMonth())) {
        resolve(accessObject?.hasAccess);
      } else {
        fetch(`https://mindjuice.herokuapp.com/parse/classes/ProductAccess/?where={\"product\":\"${productId}\",\"team\":\"${team}\",\"user\":\"${user}\"}`, {
          headers: {
            'X-Parse-Application-Id': 'mindjuice'
          }
        }).then((response) => {
          if (!response.ok) {
            resolve(false);
          } else {
            return response.json();
          }
        }).then(response => {
          if (response?.results?.length > 0) {
            const userAccess = response.results[0];
            const accessPeriode: any = (accessPeriod !== undefined ? accessPeriod : 365)
            var time_difference = new Date().getTime() - new Date(userAccess.updatedAt).getTime();
            const daysLeft = accessPeriode - Math.round(time_difference / (1000 * 60 * 60 * 24));
            let hasAccess;
            if (daysLeft > 0 || accessPeriod == undefined || accessPeriode === "") {
              hasAccess = true;
            } else {
              hasAccess = false;
            }
            localStorage.setItem(`Mindjuice_hasUserAccessToProductAndTeam_${productId}_${team}`, JSON.stringify({
              hasAccess: hasAccess,
              date: new Date()
            }));
            resolve(hasAccess);
          } else {
            localStorage.setItem(`Mindjuice_hasUserAccessToProductAndTeam_${productId}_${team}`, JSON.stringify({
              hasAccess: false,
              date: new Date()
            }));
            resolve(false);
          }
        }).catch((error) => {
          console.log(error);
          resolve(false);
        });
      }
    });
  }

  public static getProductsThatUserHasAccessTo(user: string): Promise<IProduct[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/ProductAccess/?where={\"user\":\"${user}\"}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static productAccess(productId: string, user: string): Promise<any[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/ProductAccess/?where={\"product\":\"${productId}\",\"user\":\"${user}\"}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve([]);
        } else {
          resolve(response.json());
        }
      }).catch((error) => {
        console.log(error);
        resolve([]);
      });
    });
  }

  public static allowAccessToProduct(productId: string, user: string, team?: string): Promise<any> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/classes/ProductAccess/`;
      fetch(
        url, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice',
        },
        body: JSON.stringify({
          product: productId,
          user: user.replace(" ", ""),
          team: team
        })
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          resolve(response.json());
        }
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static updateAccessToProduct(product: IProductAccess): Promise<number> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/classes/ProductAccess/${product.objectId}`;
      fetch(
        url, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice',
        },
        body: JSON.stringify({
          product: product.productId,
          team: product.team
        })
      }).then((response) => {
        if (!response.ok) {
          resolve(500);
        } else {
          resolve(200);
        }
      }).catch((error) => {
        console.log(error);
        resolve(500);
      });
    });
  }

  public static removeAccessToProduct(objectId: string): Promise<number> {
    return new Promise((resolve) => {
      const url = `https://mindjuice.herokuapp.com/parse/classes/ProductAccess/${objectId}`;
      fetch(
        url, {
        method: "DELETE",
        headers: {
          'X-Parse-Application-Id': 'mindjuice',
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(500);
        } else {
          resolve(200);
        }
      }).catch((error) => {
        console.log(error);
        resolve(500);
      });
    });
  }

  public static getUsersWithAccessToProduct(productId: string): Promise<IProductAccess[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/ProductAccess/?where={\"product\":\"${productId}\"}&limit=1000`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        let orderedUsers = response.results.sort(function (a, b) {
          if (a.user !== undefined && b.user !== undefined) {
            var keyA = a.user.toUpperCase(),
              keyB = b.user.toUpperCase();
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
          }
          return 0;
        });
        resolve(orderedUsers);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  /////////////////////////////// Team //////////////////////////////////


  public static createTeam(team: ITeam): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Teams/`, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(
          team
        )
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static updateTeam(team: ITeam): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Teams/${team.objectId}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          team: team.team,
          productId: team.productId,
          links: team.links,
          modules: team.modules,
          info: team.info,
          localizedInfo: team.localizedInfo
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static deleteTeam(team: ITeam): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Teams/${team.objectId}`, {
        method: "DELETE",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static getTeam(team: string): Promise<any[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Teams/?where={\"team\":\"${team}\"}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static getTeamsForProduct(productId: string): Promise<any[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Teams/?where={\"productId\":\"${productId}\"}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  ///////////////////////////// MIND PAPER ///////////////////////////////

  public static getMindPapers(): Promise<INote[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/MindPapers/?order=title&limit=1000`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static getMindPaper(mindPaperId: string): Promise<IMindPaper> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/MindPapers/?where={\"objectId\":\"${mindPaperId}\"}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results[0]);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static createMindPaper(mindPaper: IMindPaper): Promise<string> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/MindPapers/`, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(
          mindPaper
        )
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        if (response) {
          resolve(response?.objectId);
        } else {
          resolve(undefined);
        }
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static updateMindPaper(mindPaper: IMindPaper): Promise<string> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/MindPapers/${mindPaper.objectId}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          title: mindPaper.title,
          url: mindPaper.url,
          fileName: mindPaper.fileName,
          printEnabled: mindPaper.printEnabled,
          pages: mindPaper.pages
        })
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        if (response) {
          resolve(response?.objectId);
        } else {
          resolve(undefined);
        }
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  /////////////////////////////// NOTE //////////////////////////////////

  public static getNotes(userId: string): Promise<INote[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Notes/?where={\"userId\":\"${userId}\"}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static getNote(note: INote): Promise<INote> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Notes/?where={\"mindPaperId\":\"${note.mindPaperId}\",\"userId\":\"${note.userId}\"}`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        if (response.results && response.results.length > 0) {
          resolve(response.results[0]);
        } else {
          resolve(undefined);
        }
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static createNote(note: INote): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Notes/`, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(
          note
        )
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static updateNote(note: INote): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Notes/${note.objectId}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          notes: note.notes
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static deleteNote(note: INote): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/Notes/${note.objectId}`, {
        method: "DELETE",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  // NotePage

  public static getNotePages(noteId: string): Promise<INotePage[]> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/NotePages/?where={\"noteId\":\"${noteId}\"}&order=pageNumber`, {
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        }
      }).then((response) => {
        if (!response.ok) {
          resolve(undefined);
        } else {
          return response.json();
        }
      }).then(response => {
        resolve(response.results);
      }).catch((error) => {
        console.log(error);
        resolve(undefined);
      });
    });
  }

  public static createNotePage(note: INotePage): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/NotePages/`, {
        method: "POST",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify(
          note
        )
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  public static updateNotePage(note: INotePage): Promise<void> {
    return new Promise((resolve) => {
      fetch(`https://mindjuice.herokuapp.com/parse/classes/NotePages/${note.objectId}`, {
        method: "PUT",
        headers: {
          'X-Parse-Application-Id': 'mindjuice'
        },
        body: JSON.stringify({
          text: note.text
        })
      }).then((response) => {
        if (!response.ok) {
          resolve();
        } else {
          resolve();
        }
      }).catch((error) => {
        console.log(error);
        resolve();
      });
    });
  }

  // Billwerk
  public static getCustomer(email: string, environment?: string): Promise<any> {
    return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {
      fetch(`https://api.reepay.com/v1/list/customer?email=${email}`, {
        method: "GET",
        credentials: "same-origin",
        headers: {
          "Authorization": environment === "test" ? "Basic cHJpdl9hYjhhN2MzYTIwNDg2N2NmNDU5MDExYmM3M2ZkNzAzOA==" : "Basic cHJpdl85NTk2NDE1MmVkZjA1MGQyMDhlZjBlZDFlOGNhNzRjNA==",
          "Content-Type": "application/json"
        }
      }).then((response) => {
        if (!response.ok) {
          reject(response);
          return;
        }
        return response.json();
      }).then((result) => {
        resolve(result);
      })
        .catch(error => {
          reject(null);
        });
    });
  }


  public static getCustomerInvoice(customerHandle: string, environment?: string): Promise<any> {
    return new Promise<any>((resolve: (result: any) => void, reject: (error: any) => void): void => {
      fetch(`https://api.reepay.com/v1/list/invoice?customer=${customerHandle}`, {
        method: "GET",
        credentials: "same-origin",
        headers: {
          "Authorization": environment === "test" ? "Basic cHJpdl9hYjhhN2MzYTIwNDg2N2NmNDU5MDExYmM3M2ZkNzAzOA==" : "Basic cHJpdl85NTk2NDE1MmVkZjA1MGQyMDhlZjBlZDFlOGNhNzRjNA==",
          "Content-Type": "application/json"
        }
      }).then((response) => {
        if (!response.ok) {
          reject(response);
          return;
        }
        return response.json();
      }).then((result) => {
        resolve(result);
      })
        .catch(error => {
          reject(null);
        });
    });
  }

}