import * as React from 'react';
import './ProductModuleEditPanel.css';
import { AppStore } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import ProductService from '../../../../services/ProductService';
import AdminService from '../../../../services/AdminService';
import { FilePicker } from '../../../../mindjuiceApp/UI/filePicker/FilePicker';
import DocumentPicker from '../../../../mindjuiceApp/UI/documentPicker/DocumentPicker';
import AdminModuleDocument from '../../../../mindjuiceApp/UI/adminModuleDocument/AdminModuleDocument';
import { IModuleDocument } from '../../../../interfaces/IModuleDocument';
import MindPaperEditPanel from '../mindPaperEditPanel/MindPaperEditPanel';
import { IAPivot } from '../../../../mindjuiceApp/UI/pivot/Pivot';
import { Pivot } from '../../../../mindjuiceApp/pivot/Pivot';

export interface IProps {
  productId: string;
  selectedLanguage?;
  appStore?: AppStore;
  onChangeLanguage: (language) => void;
}

export interface IState {
  availablemindPapers: any[];
  selectedWorkbookType: string;
  selectedLanguage;
}

@inject('appStore')
@observer
export default class ProductModuleEditPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      availablemindPapers: [],
      selectedWorkbookType: "mindPaper",
      selectedLanguage: props.selectedLanguage ?? "danish"
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.productId !== undefined && props.productId !== this.props.productId) {
      this.getMindPapers();
    }
    if (props.selectedLanguage !== this.props.selectedLanguage) {
      this.setState({
        selectedLanguage: props.selectedLanguage ?? this.state.selectedLanguage
      });
    }
  }

  private getMindPapers(selectedObjectId?: string): void {
    ProductService.getMindPapers().then((mindPapers: any[]) => {
      this.props.appStore.mindPapers = mindPapers;
      if (selectedObjectId) {
        this.props.appStore.productToEdit.modules[this.props.appStore.productModuleIndesToEdit].mindPaperId = selectedObjectId;
      }
      this.loadMindPapers();
    });
  }

  private loadMindPapers(): void {
    const availablemindPapers = [];
    availablemindPapers.push(
      <option value="all">Vælg MindPaper</option>
    );
    this.setState({ availablemindPapers });
    if (this.props.appStore.mindPapers) {
      this.props.appStore.mindPapers.forEach((mindPaper: any) => {
        if (this.props.appStore.productModuleIndesToEdit && this.props.appStore.productToEdit?.modules[this.props.appStore.productModuleIndesToEdit].mindPaperId === mindPaper.objectId) {
          availablemindPapers.push(
            <option value={mindPaper.objectId} selected>{mindPaper.title}</option>
          );
        } else {
          availablemindPapers.push(
            <option value={mindPaper.objectId}>{mindPaper.title}</option>
          );
        }
      });
      this.setState({ availablemindPapers });
      this.forceUpdate();
    }
  }

  private array_move(arr, old_index, new_index): any[] {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  render() {
    const documents = [];
    const module = this.props.appStore.productToEdit && this.props.appStore.productToEdit?.modules && this.props.appStore.productToEdit?.modules[this.props.appStore.productModuleIndesToEdit];
    if (module?.documents?.length > 0) {
      module.documents.forEach((documentId: string, index) => {
        documents.push(
          <AdminModuleDocument
            id={documentId}
            isMobile={this.props.appStore.isMobile}
            disableMoveDown={module.documents.length - 1 === index}
            disableMoveUp={index === 0}
            selectedLanguage={this.state.selectedLanguage}
            onDelete={() => {
              module.documents.splice(index, 1);
            }}
            onMoveUp={() => {
              this.props.appStore.productToEdit.modules[this.props.appStore.productModuleIndesToEdit].documents = this.array_move(module.documents, index, index - 1);
            }}
            onMoveDown={() => {
              this.props.appStore.productToEdit.modules[this.props.appStore.productModuleIndesToEdit].documents = this.array_move(module.documents, index, index + 1);
            }}
            onUpdate={(document: IModuleDocument) => {
              this.props.appStore.productToEdit.modules[this.props.appStore.productModuleIndesToEdit].documents[index] = document;
            }}
          />
        );
      });
    }
    return (
      <div>
        <div
          className="productModuleEditPanelWrapper"
          style={{
            visibility: this.props.appStore.productModuleIndesToEdit != undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.productModuleIndesToEdit = undefined;
          }}
        />
        <div
          className="productModuleEditPanel"
          style={{
            right: this.props.appStore.productModuleIndesToEdit != undefined ? 0 : -620
          }}
        >
          <Pivot
            highlightColor="#b362fa"
            textColor="#333333"
            items={[
              {
                key: "danish",
                text: window.loc.strings.Danish,
              },
              {
                key: "english",
                text: window.loc.strings.English,
              }
            ]}
            style={{
              fontSize: "14px",
              textTransform: "uppercase",
              width: "100%"
            }}
            selectedKey={this.state.selectedLanguage}
            select={(selectedLanguage: string) => {
              this.setState({ selectedLanguage });
            }}
          />
          {this.props.appStore.productModuleIndesToEdit != undefined &&
            <div>
              <h3 style={{ width: "fit-content" }}>Modul titel</h3>
              <img width="20" height="20" style={{ marginTop: 20, marginLeft: 5 }} src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
              <input
                type="text"
                value={this.state.selectedLanguage === "danish" ? module.title : module.localizedTitle && module.localizedTitle[this.state.selectedLanguage] ? module.localizedTitle[this.state.selectedLanguage] : ""}
                onChange={(event: any) => {
                  if (this.state.selectedLanguage === "danish") {
                    this.props.appStore.productToEdit.modules[this.props.appStore.productModuleIndesToEdit].title = event.target.value;
                  } else {
                    if (!module.localizedTitle) {
                      module.localizedTitle = {};
                    }
                    module.localizedTitle[this.state.selectedLanguage] = event.target.value;
                  }
                }}
              />
              <h3 style={{ width: "fit-content" }}>Medie</h3>
              <div className="inputFieldLabel" style={{ width: "fit-content" }}>Medie URL (Vimeo eller MP3)</div>
              <img width="20" height="20" style={{ marginLeft: 5 }} src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
              <FilePicker
                url={this.state.selectedLanguage === "danish" ? module.videoUrl : module.localizedVideoUrl && module.localizedVideoUrl[this.state.selectedLanguage] ? module.localizedVideoUrl[this.state.selectedLanguage] : ""}
                placeholderText={"Indsæt Vimeo link eller upload en MP3-fil..."}
                inputWidth={this.props.appStore.isMobile ? "81%" : "87%"}
                onChange={(url: string) => {
                  if (this.state.selectedLanguage === "danish") {
                    this.props.appStore.productToEdit.modules[this.props.appStore.productModuleIndesToEdit].videoUrl = url;
                  } else {
                    if (!module.localizedVideoUrl) {
                      module.localizedVideoUrl = {};
                    }
                    module.localizedVideoUrl[this.state.selectedLanguage] = url;
                  }
                  if (url && url != undefined && url !== "" && url.indexOf("mindjuice.herokuapp.com/parse/files") !== -1) {
                    const splittedUrl = url.split(".");
                    let fileType = splittedUrl[splittedUrl.length - 1];
                    AdminService.addFile({
                      url: url,
                      fileType: fileType,
                      product: this.props.appStore.productToEdit?.productId,
                      team: undefined,
                      type: "productModuleMedia"
                    });
                  }
                }}
              />
              <div className="inputFieldLabel">MP3 coverbillede</div>
              <img width="20" height="20" style={{ marginLeft: 5 }} src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
              <FilePicker
                url={this.state.selectedLanguage === "danish" ? module.coverImage : module.localizedCoverImage && module.localizedCoverImage[this.state.selectedLanguage] ? module.localizedCoverImage[this.state.selectedLanguage] : ""}
                placeholderText={"Indsæt et link eller upload en fil..."}
                inputWidth={this.props.appStore.isMobile ? "81%" : "87%"}
                onChange={(url: string) => {
                  if (this.state.selectedLanguage === "danish") {
                    this.props.appStore.productToEdit.modules[this.props.appStore.productModuleIndesToEdit].coverImage = url;
                  } else {
                    if (!module.localizedCoverImage) {
                      module.localizedCoverImage = {};
                    }
                    module.localizedCoverImage[this.state.selectedLanguage] = url;
                  }
                  if (url && url != undefined && url !== "" && url.indexOf("mindjuice.herokuapp.com/parse/files") !== -1) {
                    const splittedUrl = url.split(".");
                    let fileType = splittedUrl[splittedUrl.length - 1];
                    AdminService.addFile({
                      url: url,
                      fileType: fileType,
                      product: this.props.appStore.productToEdit?.productId,
                      team: undefined,
                      type: "productModuleCover"
                    });
                  }
                }}
              />
              <h3>Dokumenter</h3>
              <IAPivot
                highlightColor={"#ffffff"}
                items={[
                  {
                    key: 'info',
                    text: "Information"
                  },
                  {
                    key: 'content',
                    text: "Indhold"
                  },
                  {
                    key: 'exam',
                    text: "Eksamen"
                  }
                ]}
                asTabs
                autoTabWidth
                tabBackgrondColor="gray"
                selectedTabBackgrondColor="#7a12d4"
                selectedKey={this.props.appStore.productToEdit.modules[this.props.appStore.productModuleIndesToEdit].moduleType ?? "content"}
                select={(moduleType: "content" | "exam" | "info") => {
                  this.props.appStore.productToEdit.modules[this.props.appStore.productModuleIndesToEdit].moduleType = moduleType;
                }}
                style={{
                  width: this.props.appStore.isMobile ? "100%" : "40%",
                  marginTop: 0,
                  height: 30,
                  marginBottom: 10,
                  textAlign: "center",
                  position: "relative",
                  float: "left"
                }}
                isMobile={this.props.appStore.isMobile}
              />
              {documents}
              <DocumentPicker
                document={{
                  product: this.props.appStore.productToEdit?.productId,
                  type: "productModuleDocument"
                }}
                selectedLanguage={this.state.selectedLanguage}
                isMobile={this.props.appStore.isMobile}
                onChange={(document: IModuleDocument) => {
                  if (module.documents == undefined) {
                    this.props.appStore.productToEdit.modules[this.props.appStore.productModuleIndesToEdit].documents = [];
                  }
                  module.documents.push(document.objectId);
                }}
              />
              <button
                style={{
                  clear: "both"
                }}
                onClick={() => {
                  if (this.props.appStore.productToEdit?.objectId !== undefined) {
                    ProductService.updateProduct(this.props.appStore.productToEdit).then(() => {
                      this.props.appStore.productModuleIndesToEdit = undefined;
                    });
                  } else {
                    ProductService.createProduct(this.props.appStore.productToEdit).then(() => {
                      this.props.appStore.productModuleIndesToEdit = undefined;
                    });
                  }
                }}
              >
                {this.props.appStore.productToEdit?.objectId !== undefined ? "Opdater" : "Opret"}
              </button>
              <button
                style={{
                  marginLeft: 10
                }}
                onClick={() => {
                  this.props.appStore.productModuleIndesToEdit = undefined;
                }}
              >
                Annuller
              </button>
              <button
                onClick={() => {
                  this.props.appStore.productToEdit?.modules.splice(this.props.appStore.productModuleIndesToEdit, 1);
                  this.props.appStore.productModuleIndesToEdit = undefined;
                  if (this.props.appStore.productToEdit?.objectId !== undefined) {
                    ProductService.updateProduct(this.props.appStore.productToEdit).then(() => {
                    });
                  } else {
                    ProductService.createProduct(this.props.appStore.productToEdit).then(() => {
                    });
                  }

                }}
                style={{
                  backgroundColor: "gray",
                  marginLeft: 10
                }}
              >
                Slet
              </button>
            </div>
          }
        </div>
        {this.props.appStore.productToEdit && this.props.appStore.mindPaperToEdit && !this.props.appStore.mindPaperToEdit.document &&
          <MindPaperEditPanel
            reloadMindPapers={(selectedId: string) => {
              this.props.appStore.mindPaperToEdit = undefined;
              this.getMindPapers(selectedId);
            }}
          />
        }
      </div>
    );
  }
}