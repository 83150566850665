import * as React from 'react';
import './Home.css';
import { AppStore } from '../../stores';
import { inject, observer } from 'mobx-react';
import Header from '../header/Header';
import { IProduct } from '../../interfaces/IProduct';
import Card from '../card/Card';
import { CardViewService } from '../../services/CardView.service';
import * as ReactGA from 'react-ga';
import Slider from '../slider/Slider';
import LogoBar from '../logoBar/LogoBar';
import ProductOrderPanel from '../../admin/products/productOrderPanel/ProductOrderPanel';
import EventOrderPanel from '../../admin/products/eventOrderPanel/EventOrderPanel';

export interface IProps {
  appStore?: AppStore;
}

export interface IState {
  containerWidth: number;
}

@inject('appStore')
@observer
export default class Home extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      containerWidth: undefined,
    }
  }

  public componentDidMount(): void {
    this.props.appStore.selectedTab = 1;
    if (this.props.appStore.user) {
      if (this.props.appStore.products && this.props.appStore.products.length > 0) {
        this.sortProducts();
      } else {
        this.props.appStore.getProducts().then(() => {
          this.sortProducts();
        });
      }
    }
    // Resize
    window.addEventListener('resize', () => this.resize());
    this.resize();
    setTimeout(() => {
      this.resize();
    }, 0);
    try {
      var resizeObserver = new ResizeObserver(() => {
        this.resize();
      });
      resizeObserver.observe(document.getElementById(`programs`));
    } catch {
      this.resize();
    }
  }

  private sortProducts(): void {
    this.props.appStore.sortedProducts = JSON.parse(JSON.stringify(this.props.appStore.products))
    if (localStorage.getItem("latestPrograms")) {
      let latestPrograms = JSON.parse(localStorage.getItem("latestPrograms"));
      if (latestPrograms?.length > 0) {
        latestPrograms.reverse();
        latestPrograms.forEach((id: string) => {
          this.props.appStore.sortedProducts.forEach((product: IProduct, index) => {
            if (product.objectId === id) {
              this.props.appStore.sortedProducts.splice(index, 1)
              this.props.appStore.sortedProducts.unshift(product);
            }
          });
        });
      }
      this.forceUpdate();
    }
  }

  private resize(): void {
    const programsContainer = document.getElementById("Home");
    this.setState({ containerWidth: programsContainer?.clientWidth - 80 }, () => this.forceUpdate());
  }

  render() {
    const productsToRender = [];
    let [numberOfCardsPerRow, maxCardWidthAvailable] = new CardViewService().getNumberOfCardsPerRowAndMaxCardWidthAvailable(this.state.containerWidth, 30, 300, 6);
    numberOfCardsPerRow = numberOfCardsPerRow == 0 ? 1 : numberOfCardsPerRow;
    let currentRowNumber = 1;
    const products = this.props.appStore.editMode ? this.props.appStore.products : this.props.appStore.sortedProducts;
    products.forEach((product: IProduct) => {
      let language = "danish";
      if (localStorage.getItem("language")) {
        language = localStorage.getItem("language").toLowerCase();
      }
      let teaser = product.teaser ? product.teaser.replace(/<\/?[^>]+(>|$)/g, "") : "  ";
      if (language !== "danish" && product.localizedTeaser && product.localizedTeaser[language]) {
        teaser = product.localizedTeaser[language].replace(/<\/?[^>]+(>|$)/g, "");
      }
      if (product.public || (this.props.appStore.editMode)) {
        const card = (
          <Card
            key={product.productId}
            area="home"
            width={this.props.appStore.isMobile ? screen.availWidth - 40 : (maxCardWidthAvailable <= 350 ? maxCardWidthAvailable : 350)}
            marginRight={currentRowNumber == numberOfCardsPerRow || this.props.appStore.isMobile ? 0 : 30}
            private={!product.public}
            id={product.productId}
            title={language !== "danish" && product.localizedTitle && product.localizedTitle[language] ? product.localizedTitle[language] : product.title}
            isPublic={product.public}
            image={language !== "danish" && product.localizedImage && product.localizedImage[language] ? product.localizedImage[language] : product.image}
            type={language !== "danish" && product.localizedType && product.localizedType[language] ? product.localizedType[language] : product.type}
            teaser={teaser}
            openReadMoreLinkEmbedded={product.openEmbedded}
            product={product}
            coverColor={product.coverColor}
            open={() => {
              this.props.appStore.selectedProductId = product.productId;
              if (localStorage.getItem("latestPrograms")) {
                let latestPrograms = JSON.parse(localStorage.getItem("latestPrograms"));
                latestPrograms.unshift(product.objectId);
                latestPrograms = latestPrograms.filter((x, y) => latestPrograms.indexOf(x) == y);
                localStorage.setItem("latestPrograms", JSON.stringify(latestPrograms));
              } else {
                let latestPrograms = [];
                latestPrograms.unshift(product.objectId);
                latestPrograms = latestPrograms.filter((x, y) => latestPrograms.indexOf(x) == y);
                localStorage.setItem("latestPrograms", JSON.stringify(latestPrograms));
              }
              this.forceUpdate();
              this.props.appStore.selectedProductId = product.productId;
              ReactGA.event({
                category: 'Products',
                action: `User opened product: ${product.title}`
              });
              this.forceUpdate();
            }}
          />
        );
        productsToRender.push(card);
        if (currentRowNumber == numberOfCardsPerRow) {
          currentRowNumber = 1;
        } else {
          currentRowNumber++;
        }
      }
    });
    let eventsToRender = [];
    let currentEventRowNumber = 1;
    let allEvents = 0;
    this.props.appStore.events?.forEach((product: IProduct) => {
      if (product.public || this.props.appStore.editMode) {
        allEvents++;
        eventsToRender.push(product);
        if (currentEventRowNumber == numberOfCardsPerRow) {
          currentEventRowNumber = 1;
        } else {
          currentEventRowNumber++;
        }
      }
    });
    let username = this.props.appStore.user?.name;
    const splittetName = this.props.appStore.user?.name.split(" ");
    if (splittetName?.length > 1) {
      username = splittetName[0];
    }
    let wrapperWidth;
    const home = document.getElementById("Home");
    if (home) {
      wrapperWidth = home.clientWidth;
    } else {
      this.forceUpdate();
    }
    return (
      <div
        id='Home'
        className='home'
        style={{
          top: this.props.appStore.isMobile && window.platform !== "ios" ? 0 : 50,
          left: this.props.appStore.isMobile ? 0 : 250,
          width: this.props.appStore.isMobile ? "100vw" : "calc(100vw - 250px)",
          height: this.props.appStore.isMobile ? `calc(100% - ${this.props.appStore.isMobile && window.platform !== "ios" ? 80 : 130}px)` : (this.props.appStore.podcastToPlay ? `calc(100% - ${this.props.appStore.isMobile && window.platform !== "ios" ? 101 : 151}px)` : `calc(100% - ${this.props.appStore.isMobile && window.platform !== "ios" ? 0 : 50}px)`)
        }}
      >
        {location.href.indexOf("mindPaperId") == -1 && this.props.appStore.user && this.props.appStore.isMobile && !window.location.pathname.includes("/enneagrammy") &&
          <LogoBar />
        }
        <Header
          headline={`${window.loc.strings.welcome} ${username}`}
        />
        <div className="programsBox">
          {allEvents > 0 && eventsToRender?.length > 0 &&
            <div className="container">
              <div
                className='subtitle'
                style={{
                  width: this.props.appStore.editMode ? "calc(100% - 300px)" : "100%"
                }}
              >
                {window.loc.strings.upcommingEvents}
              </div>
              {this.props.appStore.editMode &&
                <div
                  className="homeAddButton"
                  onClick={() => this.props.appStore.productToEdit = {
                    productType: "Event"
                  }}
                >
                  Tilføj event
                </div>

              }
              {this.props.appStore.editMode &&
                <div
                  className="homeAddButton"
                  style={{
                    marginRight: 10
                  }}
                  onClick={() => this.props.appStore.showEventOrderPanel = true}
                >
                  Tilpas rækkefølgen
                </div>

              }
              <Slider
                wrapperWidth={wrapperWidth}
                slides={eventsToRender}
              />
            </div>
          }
          {productsToRender?.length > 0 &&
            <div className="container">
              <div
                className='subtitle'
                style={{
                  width: this.props.appStore.editMode ? "calc(100% - 320px)" : "100%"
                }}
              >
                {window.loc.strings.programsHeader}
              </div>
              {this.props.appStore.editMode &&
                <div
                  className="homeAddButton"
                  onClick={() => this.props.appStore.productToEdit = {
                    productType: "Program"
                  }}
                >
                  Tilføj produkt
                </div>
              }
              {this.props.appStore.editMode &&
                <div
                  className="homeAddButton"
                  style={{
                    marginRight: 10
                  }}
                  onClick={() => this.props.appStore.showProductOrderPanel = true}
                >
                  Tilpas rækkefølgen
                </div>

              }
              {productsToRender}
            </div>
          }
        </div>
        <ProductOrderPanel />
        <EventOrderPanel />
      </div >
    );
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', () => this.resize());
  }

}