import * as React from 'react';
import '../../../productAccessPanel/ProductAccessPanel.css';
import { AppStore } from '../../../../../stores';
import { inject, observer } from 'mobx-react';
import ProductService from '../../../../../services/ProductService';
import { FilePicker } from '../../../../../mindjuiceApp/UI/filePicker/FilePicker';
import AdminService from '../../../../../services/AdminService';
import DocumentPicker from '../../../../../mindjuiceApp/UI/documentPicker/DocumentPicker';
import AdminModuleDocument from '../../../../../mindjuiceApp/UI/adminModuleDocument/AdminModuleDocument';
import { IModuleDocument } from '../../../../../interfaces/IModuleDocument';
import MindPaperEditPanel from '../../../productEditPanel/mindPaperEditPanel/MindPaperEditPanel';
import { IAPivot } from '../../../../../mindjuiceApp/UI/pivot/Pivot';
import { Pivot } from '../../../../../mindjuiceApp/pivot/Pivot';

export interface IProps {
  teamId: string;
  selectedLanguage;
  appStore?: AppStore;
}

export interface IState {
  availablemindPapers: any[];
  selectedWorkbookType: string;
  selectedLanguage;
}

@inject('appStore')
@observer
export default class TeamModuleEditPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      availablemindPapers: [],
      selectedWorkbookType: "mindPaper",
      selectedLanguage: props.selectedLanguage ?? "danish"
    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.teamId !== undefined && props.teamId !== this.props.teamId) {
      this.getMindPapers();
    }
    if (props.selectedLanguage !== this.props.selectedLanguage) {
      this.setState({
        selectedLanguage: props.selectedLanguage ?? this.state.selectedLanguage
      });
    }
  }

  private getMindPapers(selectedObjectId?: string): void {
    ProductService.getMindPapers().then((mindPapers: any[]) => {
      this.props.appStore.mindPapers = mindPapers;
      if (selectedObjectId) {
        this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].mindPaperId = selectedObjectId;
      }
      this.loadMindPapers();
    });
  }

  private loadMindPapers(): void {
    const availablemindPapers = [];
    availablemindPapers.push(
      <option value="all">Vælg MindPaper</option>
    );
    this.setState({ availablemindPapers });
    if (this.props.appStore.mindPapers) {
      this.props.appStore.mindPapers.forEach((mindPaper: any) => {
        if (this.props.appStore.teamModuleIndesToEdit && this.props.appStore.teamToEdit?.modules[this.props.appStore.teamModuleIndesToEdit].mindPaperId == mindPaper.objectId) {
          availablemindPapers.push(
            <option value={mindPaper.objectId} selected>{mindPaper.title}</option>
          );
        } else {
          availablemindPapers.push(
            <option value={mindPaper.objectId}>{mindPaper.title}</option>
          );
        }
      });
      this.setState({ availablemindPapers });
      this.forceUpdate();
    }
  }

  private array_move(arr, old_index, new_index): any[] {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  render() {
    const documents = [];
    if (this.props.appStore.teamToEdit && this.props.appStore.teamToEdit.modules && this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit] && this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].documents && this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].documents.length > 0) {
      this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].documents.forEach((documentId: string, index) => {
        documents.push(
          <AdminModuleDocument
            id={documentId}
            isMobile={this.props.appStore.isMobile}
            disableMoveDown={this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].documents.length - 1 === index}
            disableMoveUp={index === 0}
            selectedLanguage={this.state.selectedLanguage}
            onDelete={() => {
              this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].documents.splice(index, 1);
            }}
            onMoveUp={() => {
              this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].documents = this.array_move(this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].documents, index, index - 1);
            }}
            onMoveDown={() => {
              this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].documents = this.array_move(this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].documents, index, index + 1);
            }}
            onUpdate={() => {
              this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].documents[index] = document;
            }}
          />
        );
      });
    }
    const module = this.props.appStore.teamToEdit?.modules?.length > 0 && this.props.appStore.teamToEdit?.modules[this.props.appStore.teamModuleIndesToEdit];
    return (
      <div>
        <div
          className="productModuleEditPanelWrapper"
          style={{
            visibility: this.props.appStore.teamModuleIndesToEdit != undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.teamModuleIndesToEdit = undefined;
            this.props.appStore.isAddingModule = false;
          }}
        />
        <div
          className="productModuleEditPanel"
          style={{
            right: this.props.appStore.teamModuleIndesToEdit != undefined ? 0 : -620
          }}
        >
          <Pivot
            highlightColor="#b362fa"
            textColor="#333333"
            items={[
              {
                key: "danish",
                text: window.loc.strings.Danish,
              },
              {
                key: "english",
                text: window.loc.strings.English,
              }
            ]}
            style={{
              fontSize: "14px",
              textTransform: "uppercase",
              width: "100%"
            }}
            selectedKey={this.state.selectedLanguage}
            select={(selectedLanguage: string) => {
              this.setState({ selectedLanguage });
            }}
          />
          {this.props.appStore.teamModuleIndesToEdit != undefined &&
            <div>
              <h3>Modul titel</h3>
              <input
                type="text"
                value={this.state.selectedLanguage === "danish" ? this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].title : this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedTitle && this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedTitle[this.state.selectedLanguage] ? this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedTitle[this.state.selectedLanguage] : ""}
                onChange={(event: any) => {
                  if (this.state.selectedLanguage === "danish") {
                    this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].title = event.target.value;
                  } else {
                    if (!this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedTitle) {
                      this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedTitle = {};
                    }
                    this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedTitle[this.state.selectedLanguage] = event.target.value;
                  }
                }}
              />
              <h3>Medie</h3>
              <FilePicker
                url={this.state.selectedLanguage === "danish" ? this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].videoUrl : this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedVideoUrl && this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedVideoUrl[this.state.selectedLanguage] ? this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedVideoUrl[this.state.selectedLanguage] : ""}
                placeholderText={"Indsæt et link eller upload en mp3-fil..."}
                inputWidth={this.props.appStore.isMobile ? "81%" : "87%"}
                onChange={(url: string) => {
                  if (this.state.selectedLanguage === "danish") {
                    this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].videoUrl = url;
                  } else {
                    if (!this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedVideoUrl) {
                      this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedVideoUrl = {};
                    }
                    this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedVideoUrl[this.state.selectedLanguage] = url;
                  }
                  if (url && url != undefined && url !== "" && url.indexOf("mindjuice.herokuapp.com/parse/files") !== -1) {
                    const splittedUrl = url.split(".");
                    let fileType = splittedUrl[splittedUrl.length - 1];
                    AdminService.addFile({
                      url: url,
                      fileType: fileType,
                      product: this.props.appStore.teamToEdit.productId,
                      team: this.props.appStore.teamToEdit.team,
                      type: "teamModuleMedia"
                    });
                  }
                }}
              />
              <div className="inputFieldLabel">MP3 coverbillede</div>
              <FilePicker
                url={this.state.selectedLanguage === "danish" ? this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].coverImage : this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedCoverImage && this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedCoverImage[this.state.selectedLanguage] ? this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedCoverImage[this.state.selectedLanguage] : ""}
                placeholderText={"Indsæt et link eller upload en billedfil..."}
                inputWidth={this.props.appStore.isMobile ? "81%" : "87%"}
                onChange={(url: string) => {
                  if (this.state.selectedLanguage === "danish") {
                    this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].coverImage = url;
                  } else {
                    if (!this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedCoverImage) {
                      this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedCoverImage = {};
                    }
                    this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].localizedCoverImage[this.state.selectedLanguage] = url;
                  }
                  if (url && url != undefined && url !== "" && url.indexOf("mindjuice.herokuapp.com/parse/files") !== -1) {
                    const splittedUrl = url.split(".");
                    let fileType = splittedUrl[splittedUrl.length - 1];
                    AdminService.addFile({
                      url: url,
                      fileType: fileType,
                      product: this.props.appStore.teamToEdit.productId,
                      team: this.props.appStore.teamToEdit.team,
                      type: "teamModuleImage"
                    });
                  }
                }}
              />
              <h3>Workbook</h3>
              <IAPivot
                highlightColor={"#ffffff"}
                items={[
                  {
                    key: 'mindPaper',
                    text: "MindPaper"
                  },
                  {
                    key: 'file',
                    text: "Fil"
                  }
                ]}
                asTabs
                tabBackgrondColor="gray"
                selectedTabBackgrondColor="#7a12d4"
                selectedKey={this.state.selectedWorkbookType}
                select={(selectedTab: "mindPaper" | "file") => {
                  this.setState({ selectedWorkbookType: selectedTab });
                }}
                style={{
                  width: this.props.appStore.isMobile ? "100%" : "40%",
                  marginTop: 0,
                  height: 30,
                  marginBottom: 10,
                  textAlign: "center",
                  position: "relative",
                  float: "left"
                }}
                isMobile={this.props.appStore.isMobile}
              />
              {this.state.selectedWorkbookType === "mindPaper" ?
                <>
                  <select
                    name="cars"
                    id="cars"
                    placeholder="Vælg et MindPaper"
                    value={this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].mindPaperId}
                    onChange={(selectedOption: any) => {
                      this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].mindPaperId = selectedOption.target.value;
                      this.forceUpdate();
                    }}
                    style={{
                      width: "calc(100% - 240px)",
                      clear: "both"
                    }}
                  >
                    {this.state.availablemindPapers}
                  </select>
                  <button
                    onClick={() => {
                      this.props.appStore.mindPaperToEdit = {};
                    }}
                    style={{
                      marginLeft: 20,
                      marginRight: 0,
                      width: 100,
                      float: "left"
                    }}
                  >
                    Tilføj nyt MindPaper
                  </button>
                  <button
                    disabled={!this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].mindPaperId}
                    onClick={() => {
                      ProductService.getMindPaper(this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].mindPaperId).then((mindPaperToEdit) => {
                        this.props.appStore.mindPaperToEdit = mindPaperToEdit;
                      })
                    }}
                    style={{
                      marginLeft: 20,
                      marginRight: 0,
                      width: 100,
                      float: "left"
                    }}
                  >
                    Rediger valgt Mindpaper
                  </button>
                </>
                :
                <div style={{
                  clear: "both",
                  float: "left",
                  width: "100%"
                }}>
                  <div className="inputFieldLabel">Fil</div>
                  <FilePicker
                    url={this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].workbook}
                    placeholderText={"Indsæt et link eller upload en fil..."}
                    inputWidth={this.props.appStore.isMobile ? "81%" : "87%"}
                    onChange={(url: string) => {
                      this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].workbook = url;
                      if (url && url != undefined && url !== "" && url.indexOf("mindjuice.herokuapp.com/parse/files") !== -1) {
                        const splittedUrl = url.split(".");
                        let fileType = splittedUrl[splittedUrl.length - 1];
                        AdminService.addFile({
                          url: url,
                          fileType: fileType,
                          product: this.props.appStore.teamToEdit.productId,
                          team: this.props.appStore.teamToEdit.team,
                          type: "teamModuleFile"
                        });
                      }
                    }}
                  />
                  <div
                    className="inputFieldLabel"
                    style={{
                      clear: "both"
                    }}
                  >
                    Link tekst
                  </div>
                  <input
                    type="text"
                    value={this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].workbookText}
                    onChange={(event: any) => {
                      this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].workbookText = event.target.value;
                    }}
                  />
                  <div
                    className="flowpaper"
                    style={{
                      clear: "both"
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].openInSameWindow}
                      onChange={() => {
                        this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].openInSameWindow = this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].openInSameWindow != undefined ? !this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].openInSameWindow : true
                      }}
                      style={{
                        width: 20,
                        marginTop: -2
                      }}
                    />
                    Åben i samme fane
                  </div>
                </div>
              }
              <h3>Dokumenter</h3>
              <IAPivot
                highlightColor={"#ffffff"}
                items={[
                  {
                    key: 'info',
                    text: "Information"
                  },
                  {
                    key: 'content',
                    text: "Indhold"
                  },
                  {
                    key: 'exam',
                    text: "Eksamen"
                  }
                ]}
                asTabs
                autoTabWidth
                tabBackgrondColor="gray"
                selectedTabBackgrondColor="#7a12d4"
                selectedKey={this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].moduleType ?? "content"}
                select={(moduleType: "content" | "exam" | "info") => {
                  this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].moduleType = moduleType;
                }}
                style={{
                  width: this.props.appStore.isMobile ? "100%" : "40%",
                  marginTop: 0,
                  height: 30,
                  marginBottom: 10,
                  textAlign: "center",
                  position: "relative",
                  float: "left"
                }}
                isMobile={this.props.appStore.isMobile}
              />
              {documents}
              <DocumentPicker
                document={{
                  product: this.props.appStore.teamToEdit.productId,
                  team: this.props.appStore.teamToEdit.team,
                  type: "teamModuleDocument"
                }}
                selectedLanguage={this.state.selectedLanguage}
                isMobile={this.props.appStore.isMobile}
                onChange={(document: IModuleDocument) => {
                  if (this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].documents == undefined) {
                    this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].documents = [];
                  }
                  this.props.appStore.teamToEdit.modules[this.props.appStore.teamModuleIndesToEdit].documents.push(document.objectId);
                }}
              />
              {/* Save */}
              <button
                style={{
                  clear: "both"
                }}
                onClick={() => {
                  if (this.props.appStore.teamToEdit.objectId !== undefined) {
                    ProductService.updateProduct(this.props.appStore.teamToEdit).then(() => {
                      this.props.appStore.teamModuleIndesToEdit = undefined;
                      this.props.appStore.isAddingModule = false;
                    });
                  } else {
                    ProductService.createProduct(this.props.appStore.teamToEdit).then(() => {
                      this.props.appStore.teamModuleIndesToEdit = undefined;
                      this.props.appStore.isAddingModule = false;
                    });
                  }
                }}
              >
                {this.props.appStore.isAddingModule ? "Opret" : "Opdater"}
              </button>

              {/* Save and notify */}
              <button
                onClick={() => {
                  if (this.props.appStore.teamToEdit.objectId !== undefined) {
                    ProductService.updateTeam(this.props.appStore.teamToEdit).then(() => {
                      // Notify
                      // this.props.appStore.productToNotify = [];
                      // this.props.appStore.productToNotify.push(
                      //   {
                      //     product: this.props.appStore.selectedProduct,
                      //     selected: false,
                      //     teams: [{team: this.props.appStore.teamToEdit, selected: true}]
                      //   }
                      // );
                      this.props.appStore.notificationToEdit = {
                        objectId: "new",
                        sendToAll: false,
                        title: `${window.loc.strings.thereIsNewContentFor} ${this.props.appStore.selectedProduct.title}`,
                        text: `${window.loc.strings.thereIsNewContentFor} ${module.title}`,
                        linkText: window.loc.strings.open,
                        link: location.href,
                        receivers: [{
                          product: this.props.appStore.selectedProduct,
                          selected: false,
                          teams: [{ team: this.props.appStore.teamToEdit, selected: true }]
                        }]
                      }
                      this.props.appStore.teamModuleIndesToEdit = undefined;
                      this.props.appStore.isAddingModule = false;
                    });
                  }
                }}
              >
                {this.props.appStore.isAddingModule ? "Opret og send besked til deltagere" : "Opdater og send besked til deltagere"}
              </button>

              <button
                style={{
                  marginLeft: 10
                }}
                onClick={() => {
                  if (this.props.appStore.isAddingModule) {
                    this.props.appStore.teamToEdit.modules.splice(this.props.appStore.teamModuleIndesToEdit, 1);
                  }
                  this.props.appStore.teamModuleIndesToEdit = undefined;
                  this.props.appStore.isAddingModule = false;
                }}
              >
                Annuller
              </button>
              <button
                onClick={() => {
                  this.props.appStore.teamToEdit.modules.splice(this.props.appStore.teamModuleIndesToEdit, 1);
                  this.props.appStore.teamModuleIndesToEdit = undefined;
                  this.props.appStore.isAddingModule = false;
                }}
                style={{
                  backgroundColor: "gray",
                  marginLeft: 10
                }}
              >
                Slet
              </button>
            </div>
          }
        </div>
        {this.props.appStore.teamToEdit && this.props.appStore.mindPaperToEdit && !this.props.appStore.mindPaperToEdit.document &&
          <MindPaperEditPanel
            reloadMindPapers={(selectedId: string) => {
              this.props.appStore.mindPaperToEdit = undefined;
              this.getMindPapers(selectedId);
            }}
          />
        }
      </div>
    );
  }
}