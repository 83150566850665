import * as React from 'react';
import './TeamEditPanel.css';
import { AppStore } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import ProductService from '../../../../services/ProductService';
import { IProductLink } from '../../../../interfaces/IProductLink';
import ReactQuill from 'react-quill';
import { IProductModule } from '../../../../interfaces/IProductModule';
import TeamModuleEditPanel from './teamModuleEditPanel/TeamModuleEditPanel';
import { FilePicker } from '../../../../mindjuiceApp/UI/filePicker/FilePicker';
import AdminService from '../../../../services/AdminService';
import { IModuleDocument } from '../../../../interfaces/IModuleDocument';
import { Icon } from '../../../../mindjuiceApp/UI/icon';
import { Pivot } from '../../../../mindjuiceApp/pivot/Pivot';

export interface IProps {
  appStore?: AppStore;
  show: boolean;
  update: () => void;
}


export interface IState {
  selectedLanguage;
}


@inject('appStore')
@observer
export default class TeamEditPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      selectedLanguage: "danish"
    }
  }

  private array_move(arr, old_index, new_index): any[] {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  render() {
    let modules = [];
    if (this.props.appStore.teamToEdit && this.props.appStore.teamToEdit.modules) {
      this.props.appStore.teamToEdit.modules.forEach((productModule: IProductModule, index) => {
        modules.push(
          <div
            key={`productModule_${index}`}
            className="productModule"
          >
            <div className="productModuleTitle">
              {this.state.selectedLanguage === "danish" ? productModule.title : productModule.localizedTitle && productModule.localizedTitle[this.state.selectedLanguage] ? productModule.localizedTitle[this.state.selectedLanguage] : productModule.title}
            </div>
            <button
              style={{
                float: "right",
                cursor: "copy"
              }}
              onClick={() => {
                const moduleDocuments: string[] = [];
                if (productModule.documents && productModule.documents.length > 0) {
                  productModule.documents.forEach((documentId: string) => {
                    AdminService.getFile(documentId).then((document: IModuleDocument) => {
                      AdminService.addFile({
                        product: document.product,
                        team: document.team,
                        type: document.type,
                        url: document.url,
                        fileType: document.fileType,
                        mindPaperId: document.mindPaperId,
                        icon: document.icon,
                        documentTitle: document.documentTitle,
                        openInSameWindow: document.openInSameWindow,
                        openInPopup: document.openInPopup,
                        openEmbedded: document.openEmbedded
                      }).then((newDocument: IModuleDocument) => {
                        moduleDocuments.push(newDocument.objectId);
                        if (moduleDocuments.length === productModule.documents.length) {
                          this.props.appStore.copiedModule = {
                            title: productModule.title,
                            videoUrl: productModule.videoUrl,
                            workbook: productModule.workbook,
                            workbookText: productModule.workbookText,
                            coverImage: productModule.coverImage,
                            openInSameWindow: productModule.openInSameWindow,
                            flowpaper: productModule.flowpaper,
                            documents: moduleDocuments
                          };
                        }
                      });
                    });
                  });
                } else {
                  this.props.appStore.copiedModule = {
                    title: productModule.title,
                    videoUrl: productModule.videoUrl,
                    workbook: productModule.workbook,
                    workbookText: productModule.workbookText,
                    coverImage: productModule.coverImage,
                    openInSameWindow: productModule.openInSameWindow,
                    flowpaper: productModule.flowpaper,
                    documents: moduleDocuments
                  };
                  console.log(this.props.appStore.copiedModule);
                }
              }}
            >
              <Icon
                title={"Copy"}
                size={18}
                color={"#ffffff"}
                style={{
                }}
                onClick={() => { }}
              />
            </button>
            <button
              style={{
                float: "right"
              }}
              onClick={() => {
                this.props.appStore.teamModuleIndesToEdit = index;
              }}
            >
              <Icon
                title={"Edit"}
                size={18}
                color={"#ffffff"}
                style={{
                }}
                onClick={() => { }}
              />
            </button>
            <button
              style={{
                float: "right",
                opacity: index === this.props.appStore.teamToEdit.modules.length - 1 ? 0.5 : 1
              }}
              disabled={index === this.props.appStore.teamToEdit.modules.length - 1}
              onClick={() => {
                this.props.appStore.teamToEdit.modules = this.array_move(this.props.appStore.teamToEdit.modules, index, index + 1);
              }}
            >
              <Icon
                title={"ArrowDown"}
                size={18}
                color={"#ffffff"}
                style={{
                }}
                onClick={() => { }}
              />
            </button>
            <button
              style={{
                float: "right",
                opacity: index === 0 ? 0.5 : 1
              }}
              disabled={index === 0}
              onClick={() => {
                this.props.appStore.teamToEdit.modules = this.array_move(this.props.appStore.teamToEdit.modules, index, index - 1);
              }}
            >
              <Icon
                title={"ArrowUp"}
                size={18}
                color={"#ffffff"}
                style={{
                }}
                onClick={() => { }}
              />
            </button>
          </div>
        );
      });
    }
    let links = [];
    if (this.props.appStore.teamToEdit && this.props.appStore.teamToEdit.links) {
      this.props.appStore.teamToEdit.links.forEach((link: IProductLink, index) => {
        links.push(
          <div
            key={`productModule_${index}`}
            className="linkInput"
          >
            <div className="inputFieldLabel" style={{ width: "fit-content", height: 35,clear: "both", float: "left" }}>Link eller fil</div>
            <img width="20" height="20" style={{ marginLeft: 5, marginTop: 15, float: "left" }} src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
            <FilePicker
              url={this.state.selectedLanguage === "danish" ? link.url : link.localizedUrl && link.localizedUrl[this.state.selectedLanguage] ? link.localizedUrl[this.state.selectedLanguage] : ""}
              placeholderText={"Indsæt et link eller upload en fil..."}
              inputWidth={this.props.appStore.isMobile ? "79%" : "87%"}
              onChange={(url: string) => {
                if (this.state.selectedLanguage === "danish") {
                  link.url = url;
                } else {
                  if (!link.localizedUrl) {
                    link.localizedUrl = {};
                  }
                  link.localizedUrl[this.state.selectedLanguage] = url;
                }
                if (url && url != undefined && url !== "" && url.indexOf("mindjuice.herokuapp.com/parse/files") !== -1) {
                  const splittedUrl = url.split(".");
                  let fileType = splittedUrl[splittedUrl.length - 1];
                  AdminService.addFile({
                    url: url,
                    fileType: fileType,
                    product: this.props.appStore.teamToEdit.productId,
                    team: this.props.appStore.teamToEdit.team,
                    type: "teamLink"
                  });
                }
              }}
            />
            <div className="inputFieldLabel" style={{ width: "fit-content", height: 35, clear: "both", float: "left" }}>Knaptekst</div>
            <img width="20" height="20" style={{ marginLeft: 5, marginTop: 15, float: "left" }} src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
            <input
              type="text"
              value={this.state.selectedLanguage === "danish" ? link.buttonTitle : link.localizedButtonTitle && link.localizedButtonTitle[this.state.selectedLanguage] ? link.localizedButtonTitle[this.state.selectedLanguage] : ""}
              onChange={(event: any) => {
                if (this.state.selectedLanguage === "danish") {
                  link.buttonTitle = event.target.value;
                } else {
                  if (!link.localizedButtonTitle) {
                    link.localizedButtonTitle = {};
                  }
                  link.localizedButtonTitle[this.state.selectedLanguage] = event.target.value;
                }
              }}
              placeholder="Knaptekst"
            />
            <div className="flowpaper">
              <input
                type="checkbox"
                checked={link.openInSameWindow}
                onChange={() => {
                  link.openInSameWindow = !link.openInSameWindow;
                }}
                style={{
                  width: 20
                }}
              />
              Åben i samme fane
            </div>
            <div style={{
              clear: "both"
            }}>
              <button
                style={{
                  marginTop: 10,
                  float: "right",
                  backgroundColor: "red"
                }}
                onClick={() => {
                  this.props.appStore.teamToEdit.links.splice(index, 1);
                }}
              >
                <Icon
                  title={"Trash"}
                  size={18}
                  color={"#ffffff"}
                  style={{
                  }}
                  onClick={() => { }}
                />
              </button>
              <button
                style={{
                  marginTop: 10,
                  float: "right",
                  opacity: this.props.appStore.teamToEdit.links.length - 1 === index ? 0.5 : 1
                }}
                disabled={this.props.appStore.teamToEdit.links.length - 1 === index}
                onClick={() => {
                  this.props.appStore.teamToEdit.links = this.array_move(this.props.appStore.teamToEdit.links, index, index + 1);
                }}
              >
                <Icon
                  title={"ArrowDown"}
                  size={18}
                  color={"#ffffff"}
                  style={{
                  }}
                  onClick={() => { }}
                />
              </button>
              <button
                style={{
                  marginTop: 10,
                  float: "right",
                  opacity: index === 0 ? 0.5 : 1
                }}
                disabled={index === 0}
                onClick={() => {
                  this.props.appStore.teamToEdit.links = this.array_move(this.props.appStore.teamToEdit.links, index, index - 1);
                }}
              >
                <Icon
                  title={"ArrowUp"}
                  size={18}
                  color={"#ffffff"}
                  style={{
                  }}
                  onClick={() => { }}
                />
              </button>
            </div>
          </div>
        );
      });
    }
    return (
      <div>
        <div
          className="teamEditPanelWrapper"
          style={{
            visibility: this.props.appStore.teamToEdit !== undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.teamToEdit = undefined;
          }}
        />
        <div
          className="teamEditPanel"
          style={{
            right: this.props.appStore.teamToEdit !== undefined ? 0 : -620
          }}
        >
          <div className="editPanelCloseBtn"
            onClick={() => this.props.appStore.teamToEdit = undefined}
          />
          {this.props.appStore.teamToEdit &&
            <div className="addUserAccess">
              <div style={{
                clear: "both"
              }}>
                <Pivot
                  highlightColor="#b362fa"
                  textColor="#333333"
                  items={[
                    {
                      key: "danish",
                      text: window.loc.strings.Danish,
                    },
                    {
                      key: "english",
                      text: window.loc.strings.English,
                    }
                  ]}
                  style={{
                    fontSize: "14px",
                    textTransform: "uppercase",
                    width: "100%"
                  }}
                  selectedKey={this.state.selectedLanguage}
                  select={(selectedLanguage: string) => {
                    this.setState({ selectedLanguage });
                  }}
                />
                <h3>Opdater hold:</h3>
                <div className="inputFieldLabel">Hold navn</div>
                <input
                  type="text"
                  value={this.props.appStore.teamToEdit.team}
                  onChange={(event: any) => {
                    this.props.appStore.teamToEdit.team = event.target.value;
                  }}
                />
                <div
                  style={{
                    clear: "both",
                    float: "left",
                    width: "100%"
                  }}
                >
                  <div
                    style={{
                      clear: "both",
                      float: "left",
                      width: "fit-content",
                      marginRight: 5
                    }}
                  >
                    Info
                  </div>
                  <img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/google-translate.png" alt="google-translate" />
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        ['link'],
                        ['clean'],
                        ['video'],
                        ['image'],
                      ],
                      clipboard: {
                        matchVisual: false
                      }
                    }}

                    value={this.state.selectedLanguage === "danish" ? this.props.appStore.teamToEdit.info : this.props.appStore.teamToEdit.localizedInfo && this.props.appStore.teamToEdit.localizedInfo[this.state.selectedLanguage] ? this.props.appStore.teamToEdit.localizedInfo[this.state.selectedLanguage] : ""}
                    onChange={(html) => {
                      if (this.state.selectedLanguage === "danish") {
                        this.props.appStore.teamToEdit.info = html;
                      } else {
                        if (!this.props.appStore.teamToEdit.localizedInfo) {
                          this.props.appStore.teamToEdit.localizedInfo = {};
                        }
                        this.props.appStore.teamToEdit.localizedInfo[this.state.selectedLanguage] = html;
                      }
                    }}
                    style={{
                      height: 200,
                      float: "left",
                      marginBottom: 70,
                      marginTop: 7,
                      width: "100%"
                    }}
                  />
                </div>
                <div style={{
                  clear: "both",
                  float: "left",
                  marginTop: 20,
                  width: "100%"
                }}>
                  <h4>Links:</h4>
                  {links}
                  <button
                    style={{
                      marginTop: 20,
                      marginBottom: 30
                    }}
                    onClick={() => {
                      if (this.props.appStore.teamToEdit.links === undefined) {
                        this.props.appStore.teamToEdit.links = [];
                      }
                      this.props.appStore.teamToEdit.links.push({
                        url: "",
                        buttonTitle: ""
                      });
                    }}
                  >
                    Tilføj link
                  </button>
                </div>
                <h4>Moduler</h4>
                {modules}
                <button
                  style={{
                    marginTop: 20,
                    marginBottom: 30,
                    opacity: this.props.appStore.copiedModule ? 1 : 0.5
                  }}
                  disabled={!this.props.appStore.copiedModule}
                  onClick={() => {
                    if (this.props.appStore.teamToEdit.modules === undefined) {
                      this.props.appStore.teamToEdit.modules = [];
                    }
                    if (this.props.appStore.copiedModule) {
                      this.props.appStore.teamToEdit.modules.push(this.props.appStore.copiedModule);
                    }
                  }}
                >
                  Indsæt kopieret modul
                </button>
                <button
                  style={{
                    marginTop: 20,
                    marginBottom: 30
                  }}
                  onClick={() => {
                    if (this.props.appStore.teamToEdit.modules === undefined) {
                      this.props.appStore.teamToEdit.modules = [];
                    }
                    this.props.appStore.teamToEdit.modules.push({});
                    this.props.appStore.teamModuleIndesToEdit = this.props.appStore.teamToEdit.modules.length - 1;
                    this.props.appStore.isAddingModule = true;
                  }}
                >
                  Tilføj modul
                </button>
                <div>
                  <button
                    style={{
                      clear: "both"
                    }}
                    onClick={() => {
                      if (this.props.appStore.teamToEdit.objectId !== undefined) {
                        ProductService.updateTeam(this.props.appStore.teamToEdit).then(() => {
                          this.props.appStore.teamToEdit = undefined;
                          this.props.update();
                        });
                      }
                    }}
                  >
                    Opdater
                  </button>
                  <button
                    style={{
                      marginLeft: 10
                    }}
                    onClick={() => {
                      this.props.appStore.teamToEdit = undefined;
                    }}
                  >
                    Annuller
                  </button>
                  <button
                    onClick={() => {
                      ProductService.deleteTeam(this.props.appStore.teamToEdit).then(() => {
                        this.props.appStore.teamToEdit = undefined;
                        this.props.update();
                      });
                    }}
                    style={{
                      backgroundColor: "red",
                      marginLeft: 10
                    }}
                  >
                    Slet
                  </button>
                  <button
                    style={{
                      marginLeft: 10
                    }}
                    onClick={() => {
                      this.props.appStore.showTeamAccessPanelForProductAndTeam = {
                        productId: this.props.appStore.teamToEdit.productId,
                        teamId: this.props.appStore.teamToEdit.team
                      };
                    }}
                  >
                    Adgang til hold
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
        <TeamModuleEditPanel
          teamId={this.props.appStore.teamToEdit && this.props.appStore.teamToEdit.team}
          selectedLanguage={this.state.selectedLanguage}
        />
      </div>
    );
  }
}